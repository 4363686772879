.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-red {
  color: red !important;
}

/* ========================================================================= */

.aj--project__hack {
  background-image: url('headerimage.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.aj--project__hack header {
  height: 190px;
}

.aj--project__hack header .aj--logo h3 {
  text-align: center;
  width: 100%;
  margin: 15px 0 30px;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}

.no-padding-left {
  padding-left: 0 !important;
}

.aj--nav__hack * {
  box-sizing: border-box !important;
}

.aj--nav__hack .tabs {
  padding: 0;
}

.aj--nav__hack .tab-list-item {
  margin-bottom: 0 !important;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  width: 400px;
  margin: 80px auto 0;
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
}

.aj--nav__hack .tab-list-item {
  /* padding: 18px 5px !important; */
  height: 50px;
}

.aj--nav__hack .tab-list-item:hover, .aj--nav__hack .tab-list-item:focus {
  cursor: pointer;
}

.aj--nav__hack .tab-list-item {
  border: none;
  color: #fff;
  border-radius: 3px;
  height: 49px;
}

.aj--nav__hack .tab-list-item:hover, .aj--nav__hack .tab-list-item:focus {
  cursor: default;
}

.aj--nav__hack .tab-list-item {
  width: 50%;
}

.aj--nav__hack label {
  width: 80%;
  margin: 0px auto 50px;
}

.aj--nav__hack label {
  color: #fff;
  margin-bottom: 5px;
  font-weight: 100;
}

.aj--button__box {
  border-radius: 5px;
  padding: 0;
  position: relative;
  text-align: left;
  /* background-color: #fff; */
}

.aj--button__box>input[type='text'] {
  width: calc(100% - 540px);
  display: inline-block;
  border: 0;
  margin: 0;
  height: 45px;
  border-radius: 0px;
  box-shadow: 2px 2px 15px -5px #0005;
  padding: 0 15px;
}

.aj--button__box>input[type='text']:hover, .aj--button__box>input[type='text']:focus {
  outline: none;
  border: 0;
}

.aj--button__box>input.btn {
  position: absolute;
  width: 160px;
  right: 170px;
  top: 0px;
  height: 49px;
}

.aj--button__box>input.btn2 {
  position: absolute;
  width: 160px;
  right: 0px;
  top: 0px;
  height: 49px;
}

.aj--button__box>input.btn.btn-aj__hacked {
  background-color: #0F1B44;
  box-shadow: 2px 2px 15px -5px #0005;
  color: #fff;
}

.aj--button__box>input.btn2.btn-aj__reset {
  background-color: #0F1B44;
  border: 1px solid transparent;
  color: #fff;
}

main {
  padding: 30px 0;
  background-color: #fff;
}

.aj--project__hack.aj-t-blue header {
  background-color: #394878ee;
}

.aj--project__hack.aj-t-blue .aj--button__box>input.btn.btn-aj__hacked {
  background-color: #0F1B44;
}

.aj--project__hack.aj-t-blue .aj--button__box>input.btn2.btn-aj__reset {
  background-color: #0F1B44;
}

.aj--project__hack.aj-t-blue .aj--nav__hack .tab-list-item {
  border: 1px solid #0F1B44;
  /* background-color: transparent;
  color: #a7aec7; */
  box-shadow: inset 0 0 3px #252d46;
  font-weight: 700;
  display: inline-block;
  width: 200px;
  margin-top: 0;
}

.aj--project__hack.aj-t-blue .tab-list-item {
  background-color: #22305F;
  box-shadow: 0 0 0 #22305F;
}

.aj--project__hack.aj-t-blue main .react-bs-table .table thead {
  background-color: #6A8CCAbb;
}

.aj--project__hack.aj-t-blue main .react-bs-table .table thead th {
  color: #fff;
}

.aj--project__hack.aj-t-blue main .react-bs-table .table tbody tr:nth-child(odd) {
  background-color: #6A8CCA22;
}

.aj--project__hack.aj-t-green header {
  background-color: #2D6D69ee;
}

.aj--project__hack.aj-t-green .aj--button__box>input.btn.btn-aj__hacked {
  background-color: #002724;
}

.aj--project__hack.aj-t-green .aj--button__box>input.btn2.btn-aj__reset {
  background-color: #002724;
}

.aj--project__hack.aj-t-green .aj--nav__hack .tab-list-item {
  border: 1px solid #006F50;
  /* background-color: transparent;
  color: #93cc9c; */
  box-shadow: inset 0 0 3px #001412;
  font-weight: 800;
  display: inline-block;
  width: 200px;
  margin-top: 0;
}

.aj--project__hack.aj-t-green .tab-list-item {
  background-color: #006F50;
  box-shadow: 0 0 0 #001412;
}

.aj--project__hack.aj-t-green main .react-bs-table .table thead {
  background-color: #2D6D69bb;
}

.aj--project__hack.aj-t-green main .react-bs-table .table thead th {
  color: #fff;
}

.aj--project__hack.aj-t-green main .react-bs-table .table tbody tr:nth-child(odd) {
  background-color: #2D6D6922;
}

.aj--project__hack.aj-t-blue .loader-dice {
  background-color: #2D6D69;
}

.aj--project__hack.aj-t-green .loader-dice {
  background-color: #22305F;
}

.loader {
  width: 100%;
  height: 3px;
  position: relative;
}

.loader-dice {
  min-width: 100px;
  min-height: 3px;
  position: absolute;
  left: 0;
  top: 0;
  right: auto;
  -webkit-animation: leftright 2s infinite ease-in-out;
  /* Safari 4.0 - 8.0 */
  animation: leftright 2s infinite ease-in-out;
}

/*CSS for pagination - Pratima */

.dropdown-toggle {
  float: left;
}

.pagination {
  margin: 0px 0 !important
}

.dropdown-menu {
  /* left: 0; */
  top: 31px
}

@keyframes leftright {
  0% {
    left: 0px;
  }
  40% {
    left: 100%;
  }
  60% {
    left: 0;
    right: 0;
  }
  100% {
    left: 0;
    right: 100%;
  }
}

.aj--project__hack.aj-t-blue footer {
  background-color: #22305F;
}

.aj--project__hack.aj-t-green footer {
  background-color: #2D6D69;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  z-index: 99;
}

footer p {
  margin-bottom: 0;
  color: #fff;
  font-weight: 700;
  font-size: 10px;
}

.pagination {
  float: right;
}